import React from "react";
import styled from "@emotion/styled";
import { Link } from "gatsby";

import { BREAKPOINTS, COLORS } from "../../ui/variables";

const FilterNavigation = styled.nav`
  border-top: 1px solid ${COLORS.BLACK};
  border-bottom: 1px solid ${COLORS.BLACK};
  padding: 24px 0;
  text-transform: uppercase;

  ul {
    list-style: none;
    margin: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    font-size: 14px;
    font-weight: 600;

    @media screen and (max-width: 1242px) {
      padding: 0 40px;
    }

    @media ${BREAKPOINTS.TABLET} {
      padding: 0 30px;
    }

    @media ${BREAKPOINTS.MOBILE} {
      padding: 0 20px;
    }

    li {
      margin-right: 20px;
      margin-bottom: 0;
    }

    a:hover {
      color: ${COLORS.ORANGE};
    }
  }
`;

const CategoryLink = styled(Link)<{ selected: boolean }>`
  color: ${props => (props.selected ? COLORS.ORANGE : COLORS.BLACK)}; ;
`;

interface Props {
  activeSlug?: string;
  categories: {
    urlSlug: string;
    name: string;
  }[];
}

const CategoryNavigation = ({ categories, activeSlug }: Props) => (
  <FilterNavigation>
    <ul>
      <li>
        <CategoryLink selected={activeSlug === "All"} to="/learning-center/">
          All
        </CategoryLink>
      </li>
      {categories.map(category => (
        <li key={category.urlSlug}>
          <CategoryLink
            selected={category.urlSlug === activeSlug}
            to={`/learning-center/${category.urlSlug}`}
          >
            {category.name}
          </CategoryLink>
        </li>
      ))}
    </ul>
  </FilterNavigation>
);

export default CategoryNavigation;
