import React from "react";
import { css } from "@emotion/core";
import styled from "@emotion/styled";
import { Link } from "gatsby";
import Img from "gatsby-image";

import { BREAKPOINTS } from "../../ui/variables";
import Heading3 from "../../ui/Heading3";
import { Category, LearningCenterPost } from "../../pages/learning-center";
import ParagraphLarge from "../../ui/ParagraphLarge";

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const Post = styled(Link)`
  width: calc(92% / 3);
  margin-bottom: 80px;

  @media ${BREAKPOINTS.TABLET} {
    width: 47%;
  }

  @media screen and (max-width: 600px) {
    width: 100%;
  }
`;
interface Props {
  posts: LearningCenterPost[];
}

const Posts = ({ posts }: Props) => {
  return (
    <Container>
      {posts.map(post => (
        <Post to={post.path} key={post.path}>
          <Img
            css={css`
              margin-bottom: 32px;
            `}
            fluid={post.featureImage.fluid}
            alt={post.featureImage.title}
          />
          <Category
            css={css`
              margin-bottom: 12px;
              display: inline-block;
            `}
          >
            {post.category.name}
          </Category>
          <Heading3>{post.title}</Heading3>
          <ParagraphLarge>{post.featureText}</ParagraphLarge>
        </Post>
      ))}
    </Container>
  );
};

export default Posts;
