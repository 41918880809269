import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/core";
import { Link } from "gatsby";
import { BREAKPOINTS, COLORS } from "../../ui/variables";
import Heading4 from "../../ui/Heading4";
import { ReactComponent as ArrowRightIco } from "../../images/icons/arrow-right.svg";
import ParagraphMedium from "../../ui/ParagraphMedium";
import { LearningCenterCategory } from "../../pages/learning-center";
import Img from "gatsby-image";

const Container = styled.div`
  background: ${COLORS.LIGHT_BEIGE};
`;

const Content = styled.div`
  max-width: 1242px;
  padding: 120px 40px 40px 40px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  @media ${BREAKPOINTS.TABLET} {
    padding: 100px 30px 30px 30px;
  }

  @media ${BREAKPOINTS.MOBILE} {
    padding: 80px 20px 20px 20px;
  }
`;

const Category = styled.div`
  width: 45%;
  margin-bottom: 100px;

  @media ${BREAKPOINTS.TABLET} {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 80px;
  }

  @media screen and (max-width: 600px) {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    margin-bottom: 60px;
  }
`;

const LinkHeader = styled(Link)`
  display: block;
  font-size: 40px;
  line-height: 48px;
  font-weight: 600;
  margin-bottom: 40px;

  @media ${BREAKPOINTS.TABLET} {
    font-size: 32px;
    line-height: 35px;
    max-width: 300px;
    padding-right: 24px;
    width: 100%;
  }

  @media screen and (max-width: 600px) {
    max-width: none;
  }
`;

const Posts = styled.div`
  padding-left: 48px;
  display: flex;
  justify-content: space-between;

  @media ${BREAKPOINTS.TABLET} {
    width: 100%;
    padding-left: 0;
  }
`;

const Post = styled(Link)`
  width: 216px;

  @media ${BREAKPOINTS.TABLET} {
    width: 188px;
  }

  @media screen and (max-width: 600px) {
    width: 47%;
  }
`;

interface Props {
  categories: LearningCenterCategory[];
}

const Categories = ({ categories }: Props) => {
  return (
    <Container>
      <Content>
        {categories
          .filter(category => !!category.posts.length)
          .map(category => (
            <Category key={category.name}>
              <LinkHeader to={category.path}>
                {category.name}{" "}
                <ArrowRightIco
                  width="24"
                  height="19"
                  css={css`
                    fill: ${COLORS.ORANGE};
                  `}
                />
              </LinkHeader>

              <Posts>
                {category.posts.map(post => (
                  <Post to={post.path} key={post.path}>
                    <Img
                      css={css`
                        margin-bottom: 24px;
                      `}
                      fluid={post.fluidImage}
                    />
                    <Heading4>{post.title}</Heading4>
                    <ParagraphMedium>{post.featureText}</ParagraphMedium>
                  </Post>
                ))}
              </Posts>
            </Category>
          ))}
      </Content>
    </Container>
  );
};

export default Categories;
